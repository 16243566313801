import React from "react";
import style from "./TeamFirst.module.scss";
export interface TeamFirstState {}
export interface TeamFirstProps {}

class TeamFirst extends React.Component<TeamFirstProps, TeamFirstState> {
  render() {
    return (
      <div id="team" className={style.wrapper}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.leftColumn}>
              <p>{"o{"}</p>
            </div>
            <div className={style.rightColumn}>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Biagio <br></br>Distefano
                </p>
                <p>
                  Socio fondatore. Giurista classe ’92, laureato con lode in
                  diritto penale d’impresa nel 2015 e diplomato alla SSPL nel
                  2017. Da sempre programmatore autodidatta, dal 2017 sviluppa
                  professionalmente framework per la rappresentazione
                  machine-readable della conoscenza giuridica, occupandosi
                  contestualmente di soluzioni blockchain e smart contract. Nel
                  2018 si unisce al CIRSFID dell’Università di Bologna per la
                  realizzazione di strumenti informatico-giuridici nell’ambito
                  di progetti per la Corte Costituzionale, Corte dei Conti,
                  Presidenza del Consiglio dei Ministri, Nazioni Unite e UE. Dal
                  2019 al 2021 lavora come Research Associate presso il{" "}
                  <i>Department of Innovation and Digitalisation in Law</i>{" "}
                  dell'Università di Vienna 
                  nell'ambito del programma Marie Skłodowska-Curie Actions – Law, Science & Technology Joint
                  Doctorate: Rights of Internet of Everything
                  (MSCA-LAST-JD-RIoE), approfondendo in particolare il tema{" "}
                  <i>
                    DLTs beyond financial applications: eDemocracy and new forms
                    of Governance.
                  </i>{" "} 
                  Dal 2021 prosegue il dottorato presso il medesimo Dipartimento in via indipendente.
                  
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>Dinamo</p>
                <p>
                  Socio fondatore. Dinamo è una società che opera da più di 10
                  anni nei settori della comunicazione e marketing, design e
                  sviluppo di applicativi web e integrazione di sistemi per
                  building & industrial automation. Dinamo integra strategia,
                  creatività e tecnologia, per realizzare prodotti e servizi
                  digitali allo stato dell’arte, pensati dal punto di vista
                  dell’utente e progettati per ottimizzare i processi e creare
                  relazioni con i mercati.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamFirst;
