import React from "react";
import style from "./Footer.module.scss";
import logo from "../../assets/img/logo.svg";
export interface FooterState {}
export interface FooterProps {}

class Footer extends React.Component<FooterProps, FooterState> {
  render() {
    return (
      <footer id="contatti" className={style.container}>
        <div className={style.content}>
          <img className={style.logo} src={logo} alt='LES'/>
        </div>
        <div className={style.contacts}>
          <p>
            <a href="mailto:info@lawengineeringsystems.com">
              info@lawengineeringsystems.com
            </a>
          </p>
        </div>
        <p className={style.footerNotes}>
          <small>
            Law Engineering Systems s.r.l., Belvedere N83, Colle di Val d'Elsa - 53034 (SI)
            <br />
            P.IVA: 01500710528</small>
        </p>
      </footer>
    );
  }
}

export default Footer;
