import React from "react";
import style from "./About.module.scss";
export interface AboutState {}
export interface AboutProps {}

class About extends React.Component<AboutProps, AboutState> {
  render() {
    return (
      <div id='about' className={style.wrapper}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.leftColumn}>
              <p>{"o{"}</p>
            </div>
            <div className={style.rightColumn}>
              <p>
                  Nata dall'unione di forze ed entusiasmi di esperti
                  del settore giuridico e di quello delle nuove tecnologie, la{" "}
                  <span className="bold">Law Engineering Systems</span> è stata
                  fondata come società a responsabilità limitata nel 2019.
                  <br></br>
                  <br></br>
                  Grazie alle molteplici competenze che si incontrano nella nostra impresa,
                  possiamo offrire un accesso alla conoscenza giuridica istantaneo e 
                  facilmente fruibile, da utenti esperti e non. 
                  <br></br>
                  <br></br>
                  Consentiamo a studiosi e professionisti
                  di potersi dedicare alla risoluzione delle problematiche quotidiane,
                  in sede accademica o in aula di tribunale, contando sul supporto
                  quasi invisibile dei più innovativi strumenti tecnologici.
                  <br></br>
                  <br></br>
                  Grazie alle nostre tecnologie è possibile tuffarsi in
                  un’esplorazione innovativa del diritto vivente,
                  individuando schemi di conoscenza finora inaccessibili.
                  <br></br>
                  <br></br>
                  A necessario complemento, l’ultradecennale esperienza
                  in tema di design e sviluppo di applicazioni web ci consente di abbattere
                  la nuova frontiera del Legal Design.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
