import React from "react";
import style from "./Header.module.scss";

export interface HeaderState {}
export interface HeaderProps {}

class Header extends React.Component<HeaderProps, HeaderState> {
  link(ev: any, anchor: string) {
    ev.preventDefault();
    if (document.getElementById(`${anchor}`)) {
      window.scrollTo({
        behavior: "smooth",
        // @ts-ignore
        top: document.getElementById(`${anchor}`).offsetTop - 80,
      });
    }
  }

  render() {
    return (
      <header className={style.wrapper}>
        <div className={style.container}>
          <div>
            <p>
              <a onClick={(e) => this.link(e, "about")} href="#about">
                About
              </a>
            </p>
          </div>
          <div>
            <p>
              <a onClick={(e) => this.link(e, "servizi")} href="#servizi">
                Servizi
              </a>
            </p>
          </div>
          <div>
            <p>
              <a onClick={(e) => this.link(e, "team")} href="#team">
                Team
              </a>
            </p>
          </div>
          <div>
            <p>
              <a onClick={(e) => this.link(e, "contatti")} href="#contatti">
                Contatti
              </a>
            </p>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
