import React from "react";
import style from "./TeamSecond.module.scss";
export interface TeamSecondState {}
export interface TeamSecondProps {}

class TeamSecond extends React.Component<TeamSecondProps, TeamSecondState> {
  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.leftColumn}>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Stefano <br></br>Franci
                </p>
                <p>
                  Socio fondatore. Nato nel 1979. Laureato in Scienze della Comunicazione, fa del
                  marketing prima disciplina di studio e successivamente di lavoro. Con una grande
                  passione per la massmediologia, le nuove tecnologie e l’interazione uomo-macchina,
                  crede da subito nel progetto Law Engineering Systems perché ritiene che ogni
                  medium cambi costantemente il nostro modo di rapportarci col mondo e quindi, in
                  definitiva, la qualità della nostra vita. Molto fiero d’aver fatto, nel ruolo di
                  business developer, da <i>trait d’union</i> per la nascita di LES.
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Tommaso<br></br> Pifferi
                </p>
                <p>
                  Socio fondatore. Sviluppatore full stack classe ‘93, da sempre interessato nelle
                  ultime tecnologie di web development, con focus sulla programmazione funzionale.
                  Ha esperienze di lavoro in ambito startup, consulenza e sviluppo prodotto. Ha
                  contribuito in maniera chiave allo sviluppo del primo prototipo delle nostre
                  soluzioni tecnologiche.
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Marco <br></br>
                  Barone
                </p>
                <p>
                  Socio fondatore. Avvocato classe ’91, laureato con lode in diritto del lavoro nel
                  2015 e diplomato alla SSPL nel 2017. Da sempre appassionato di tecnologia e
                  attento ai temi legali che la circondano, nel 2019 consegue il Master
                  Universitario di secondo livello in Cybersecurity & Digital Forensics (110/110)
                  presso l’università di Roma Tor Vergata, specializzandosi in ambito di sicurezza
                  digitale e protezione dei dati personali, con alte competenze trasversali
                  nell’ambito giuridico-informatico-criminologico.
                </p>
              </div>
            </div>
            <div className={style.rightColumn}>
              <p>{"}o"}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamSecond;
