import React from "react";
import Logo from "./sections/logo/Logo";
import About from "./sections/about/About";
import Services from "./sections/services/Services";
import TeamSecond from "./sections/teamSecond/TeamSecond";
import TeamFirst from "./sections/teamFirst/TeamFirst";
import Footer from "./sections/footer/Footer";
import Header from "./components/header/Header";

export interface AppState {}

export interface AppProps {}

class App extends React.Component<AppProps, AppState> {
  render() {
    return (
      <div className="App">
        <Header />
        <Logo />
        <About />
        <Services />
        <TeamFirst />
        <TeamSecond />
        <Footer />
      </div>
    );
  }
}

export default App;
