import React from "react";
import style from "./Services.module.scss";
export interface ServicesState {}
export interface ServicesProps {}

class Services extends React.Component<ServicesProps, ServicesState> {
  render() {
    return (
      <div id="servizi" className={style.wrapper}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.leftColumn}>
              <div className={style.service}>
                <p className={style.serviceName}>Data Analysis</p>
                <p>
                  Analizziamo la conoscenza giuridica in modi mai visti prima,
                  grazie alle nostre innovative tecnologie di rappresentazione e
                  correlazione documentale.
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Blockchain<br></br> e Smart Contract
                </p>
                <p>
                  Consulenza e formazione. Guidiamo studi legali e aziende dalle
                  fondamenta della blockchain agli utilizzi più avanzati.
                  <br></br>
                  <br></br>
                  Sviluppo di soluzioni personalizzate. Dalla teoria alla
                  pratica: non più solo ipotesi giuridiche o tecnologiche, ma
                  applicazioni innovative per ridefinire il quotidiano.
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Privacy, <br></br>
                  Data Protection & <br></br>
                  Cybersecurity
                </p>
                <p>
                  Offriamo formazione specializzata, rivolta ad aziende e studi
                  professionali che intendano assolvere i requisiti previsti
                  dalle normative vigenti, formarsi sugli standard di settore, o
                  sensibilizzare il proprio personale.<br></br>
                  <br></br>
                  Focus interattivi sulla più recente normativa e risvolti
                  applicativi concreti, personalizzati in base alle esigenze del
                  cliente.
                </p>
              </div>
              <div className={style.service}>
                <p className={style.serviceName}>
                  Soluzioni <br></br>
                  su misura
                </p>
                <p>
                  Aiutiamo il tuo studio a reinventare il modo di praticare la
                  professione forense, grazie alle più moderne tecnologie che
                  sviluppiamo ogni giorno.
                </p>
              </div>
            </div>
            <div className={style.rightColumn}>
              <p>{"[ i ]"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
