import React from "react";
import style from "./Logo.module.scss";
import logo from '../../assets/img/logo.svg'
export interface LogoState {}
export interface LogoProps {}

class Logo extends React.Component<LogoProps, LogoState> {
  render() {
    return (
      <div id="main" className={style.container}>
        <div className={style.content}>
          <img className={style.logo} src={logo} alt='LES' />
        </div>
      </div>
    );
  }
}

export default Logo;
